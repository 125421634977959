import React from 'react';
import { useHistory } from 'react-router-dom';
import SplashForm from 'forms/SplashForm';
import EditSplashContext from 'contexts/EditSplashContext';
import { getSplash } from 'services/splashServices';
import Splash from 'types/models/Splash';
import AppContext from 'contexts/AppContext';
import { loadImage } from 'services/imageServices';

export default (): JSX.Element => {
  const history = useHistory();
  const { setIsLoading } = React.useContext(AppContext);
  const { selectedSplash } = React.useContext(EditSplashContext);
  const [completeSplash, setCompleteSplash] = React.useState<Splash | null>(null);

  const load = React.useCallback(async (id: string) => {
    setIsLoading(true);
    const splash = await getSplash(id);
    if (!splash) {
      setIsLoading(false);
      history.push('/private/splashes');
      return;
    }

    if (splash.promotionBannerImage?.path) {
      await loadImage(splash.promotionBannerImage);
    }

    if (splash.backgroundMainImage?.path) {
      await loadImage(splash.backgroundMainImage);
    }

    if (splash.backgroundHeaderImage?.path) {
      await loadImage(splash.backgroundHeaderImage);
    }

    if (splash.backgroundFooterImage?.path) {
      await loadImage(splash.backgroundFooterImage);
    }

    setCompleteSplash(splash);
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    if (!selectedSplash) {
      history.push('/private/common/splashes');
    } else {
      load(selectedSplash.id);
    }
  }, []);

  if (!completeSplash) return <></>;
  return <SplashForm splash={completeSplash} setSplash={setCompleteSplash} />;
};
