import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import ListUserPage from 'pages/private/common/ListUserPage';

export default ({ match }: RouteComponentProps): JSX.Element => {

  return (
    <Switch>
      <Route exact={true} path={`${match.url}`} component={ListUserPage} />
      <Redirect to="/private/welcome" />
    </Switch>
  );
};
