import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  DocsIcon,
  UsersIcon,
  VehicleIcon,
} from 'components/icons/Icons';
import 'styles/pages/WelcomePage.scss';
import AppContext from 'contexts/AppContext';
import ProfileTypes from 'types/enums/ProfileTypes';

export default (): JSX.Element => {
  const history = useHistory();
  const { authData } = React.useContext(AppContext);

  return (
    <>
      <section className="Starting">
        <h1>Bienvenido a Splash Wifi</h1>
        <p>Haga click en cualquier operación para empezar</p>
      </section>
      <section className="Container">
        {authData?.user.profileTypeId === ProfileTypes.Admin && (
          <div
            className="Tool"
            onClick={() => {
              history.push('/private/venues');
            }}
          >
            <VehicleIcon />
            <strong>Venues</strong>
          </div>
        )}
        {(authData?.user.showUserSection || authData?.user.showUserSplashSection || authData?.user.profileTypeId === ProfileTypes.Admin) && (
          <div
            className="Tool"
            onClick={() => {
              history.push('/private/users');
            }}
          >
            <UsersIcon />
            <strong>Usuarios</strong>
          </div>
        )}

        {(authData?.user.showSplashSection || authData?.user.showUserSplashSection || authData?.user.profileTypeId === ProfileTypes.Admin) && (
          <div
            className="Tool"
            onClick={() => {
              history.push('/private/splashes');
            }}
          >
            <DocsIcon />
            <strong>Splashes</strong>
          </div>
        )}

        {authData?.user.profileTypeId === ProfileTypes.Admin && (
          <div
            className="Tool"
            onClick={() => {
              history.push('/private/user-editors');
            }}
          >
            <UsersIcon />
            <strong>Editores de venue</strong>
          </div>
        )}

      </section>
    </>
  );
};
