import React from 'react';
import { ClipLoader } from 'react-spinners';

export default ({ isLoading }: { isLoading: boolean }): JSX.Element => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        zIndex: 9999,
      }}
    >
      <ClipLoader size={50} color={'#123abc'} loading={isLoading} />
      {isLoading && <div style={{ marginTop: '6px' }}>Cargando...</div>}
    </div>
  );
};
