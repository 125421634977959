import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';
import SelectField from 'components/SelectField';
import ConfirmDialog from 'components/ConfirmDialog';
import UserFormProps from 'types/forms/UserFormProps';
import User from 'types/models/User';
import { storeUser, updateUser } from 'services/userService';
import { UserFormCreate, UserFormEdit } from './schemas/UserForm';
import ProfileTypes from 'types/enums/ProfileTypes';
import Venue from 'types/models/Venue';

export default ({ venues, user }: UserFormProps): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [error, setError] = React.useState('');

  const venuesForSelect = (venues: Venue[]) => {
    return venues.map((venue) => {
      return {
        label: venue.code,
        value: venue.id,
      };
    });
  };

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };


  const onConfirm = async (): Promise<void> => {
    const userData = {
      ...formik.values
    };

    try {
      let result: User | null;

      if (user) {
        result = await updateUser(user.id, userData);
      } else {
        result = await storeUser(userData);
      }

      if (result) {
        history.go(-1);
      }
    } catch (error: any) {
      console.log(error);
      setError(`${error}`);
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: user?.email ?? '',
      password: '',
      fullname: user?.fullname ?? '',
      venueId: user?.venue?.id ?? '',
      profileTypeId: ProfileTypes.VenueEditor,
      showUserSection: user?.showUserSection ?? false,
      showSplashSection: user?.showSplashSection ?? false,
      showUserSplashSection: user?.showUserSplashSection ?? false,
    },
    validationSchema: user ? UserFormEdit : UserFormCreate,
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  // React.useEffect(() => {
  //   const value = formik.values.showUserSplashSection;
  //   formik.setFieldValue("showUserSection", value);
  //   formik.setFieldValue("showSplashSection", value);
  // }, [formik.values.showUserSplashSection]);

  // React.useEffect(() => {
  //   const value = formik.values.showUserSection && formik.values.showSplashSection;
  //   formik.setFieldValue("showUserSplashSection", value);

  // }, [formik.values.showUserSection, formik.values.showSplashSection]);

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <SelectField
          name="venueId"
          label={"Seleccionar el venue"}
          placeholder={'Seleccionar el venue'}
          value={formik.values.venueId}
          options={venuesForSelect(venues)}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.venueId}
          error={formik.errors.venueId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <TextField
          margin="dense"
          fullWidth
          id="email"
          name="email"
          label={'Email'}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          disabled={formik.isSubmitting}
        />
        <TextField
          margin="dense"
          fullWidth
          id="password"
          name="password"
          type="password"
          label={'Contraseña'}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          disabled={formik.isSubmitting}
        />
        <FormControlLabel
          id="showUserSection"
          name="showUserSection"
          control={<Switch checked={formik.values.showUserSection} onChange={formik.handleChange} />}
          label={'Puede ver sección de usuarios'}
          disabled={formik.isSubmitting}
        />
        <FormControlLabel
          id="showSplashSection"
          name="showSplashSection"
          control={<Switch checked={formik.values.showSplashSection} onChange={formik.handleChange} />}
          label={'Puede ver sección de splashes'}
          disabled={formik.isSubmitting}
        />
        <FormControlLabel
          id="showUserSplashSection"
          name="showUserSplashSection"
          control={<Switch checked={formik.values.showUserSplashSection} onChange={formik.handleChange} />}
          label={'Puede ver ambas secciones'}
          disabled={formik.isSubmitting}
        />

        <TextField
          margin="dense"
          fullWidth
          id="fullname"
          name="fullname"
          label={'Nombre del usuario'}
          value={formik.values.fullname}
          onChange={formik.handleChange}
          error={formik.touched.fullname && Boolean(formik.errors.fullname)}
          helperText={formik.touched.fullname && formik.errors.fullname}
          disabled={formik.isSubmitting}
        />
        <div className="footer">
          <Button fullWidth variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
            {`${user ? 'Editar' : 'Crear'}`}
          </Button>
        </div>
        {error && (
          <div>
            <Typography color="error" variant="h6" component="h2">
              {error}
            </Typography>
          </div>
        )}
      </form>
    </>
  );
};
