import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { Edit, ArrowUpward, FilterList, GetApp, Delete, } from '@material-ui/icons';
import {
  destroySplash,
  exportSplashes,
  getSplashesWithPagination,
} from 'services/splashServices';
import AppContext from 'contexts/AppContext';
import EditSplashContext from 'contexts/EditSplashContext';
import ConfirmDialog from 'components/ConfirmDialog';
import DropDown from 'components/DropDown';
import type PaginationData from 'types/models/PaginationData';
import type Splash from 'types/models/Splash';
import type { SplashFilterParams } from 'types/models/filters';
import 'styles/pages/ListSplashesPage.scss';
import ProfileTypes from 'types/enums/ProfileTypes';

export default (): JSX.Element => {
  const history = useHistory();
  const { isLoading, setIsLoading, authData } = React.useContext(AppContext);
  const { setSelectedSplash, selectedSplash } = React.useContext(EditSplashContext);
  const [withFilter, setWithFilter] = React.useState<boolean>(false);
  const [splashPagination, setSplashPagination] = React.useState<PaginationData<Splash> | null>();
  const [filteredSplashes, setFilteredSplashes] = React.useState<Splash[]>([]);
  const [splashFilterParams, setSplashFilterParams] = React.useState<SplashFilterParams | null>({
    name: '',
    code: '',
  });
  const [sortedField, setSortedField] = React.useState<{ field: string; sort: 'asc' | 'desc' }>({
    field: '',
    sort: 'asc',
  });
  const [showFilterDropDown, setShowFilterDropDown] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const menuItems = [
    { label: 'Destino', value: 'destination_address' },
    { label: 'Origen', value: 'origin_address' },
    { label: 'Nombre', value: 'description' },
  ];

  const searchButton = React.useRef<HTMLButtonElement>(null);

  const loadData = React.useCallback(
    async (page?: number, splashFilterParams?: SplashFilterParams | null, withFilter = false) => {
      setIsLoading(true);
      const paginationData = await getSplashesWithPagination(page, splashFilterParams, withFilter);

      if (paginationData) {
        setSplashPagination(paginationData);
        setFilteredSplashes(paginationData.data);
      }

      setIsLoading(false);
    },
    [],
  );

  React.useEffect(() => {
    loadData(1, splashFilterParams, withFilter);
  }, []);

  const handleTableCellClick = async (field: string) => {
    const sort = sortedField.sort === 'asc' ? 'desc' : 'asc';
    setSortedField({ field, sort });
    const filters: SplashFilterParams = { ...splashFilterParams, sortBy: field, sortDir: sort };
    setSplashFilterParams(filters);
    setWithFilter(true);
    loadData(1, filters, withFilter);
  };

  const renderEditButton = (splash: Splash | null): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          if (isLoading) return;
          if (!splash) return;
          setSelectedSplash(splash);
          history.push(`/private/splashes/${splash.id}/edit`);
        }}
      >
        <Edit />
      </IconButton>
    );
  };


  const renderDeleteButton = (data: any): JSX.Element => (
    <IconButton
      color="inherit"
      onClick={() => {
        if (isLoading) return;
        if (!data) return;
        setSelectedSplash(data);
        setOpenDialog(true);
      }}
    >
      <Delete />
    </IconButton>
  );

  const onCancel = (): void => {
    setOpenDialog(false);
  };

  const onConfirm = async (): Promise<void> => {
    await destroySplash(selectedSplash?.id ?? '');
    loadData(1, splashFilterParams, withFilter);
  };

  const getXlsxSplashes = async () => {
    const data = await exportSplashes(splashFilterParams, withFilter);
    const a = document.createElement('a');

    if (data) {
      a.href = window.URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
      a.download = 'viajes_export.xlsx';
      a.click();
    }
  };

  const renderContent = (paginationData: Splash[] = []): JSX.Element => {
    return (
      <>
        {paginationData.map((splash: Splash) => (
          <TableRow key={splash.id}>
            <TableCell component="th" scope="row">
              {splash?.title ?? ''}
            </TableCell>
            <TableCell component="th" scope="row">
              {splash.venue?.name}
            </TableCell>
            <TableCell component="th" scope="row">
              {splash.active ? 'Sí' : "No"}
            </TableCell>
            <TableCell>
              <div className="Action--buttons">
                {renderEditButton(splash)}
                {renderDeleteButton(splash)}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <section className="Table--header">
        <h3>Todos los splashes</h3>
      </section>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <section className="Table--hero">
        <div className="Table--wrapper">
          <div className="Filter--wrapper">
            <Button
              size="small"
              variant="contained"
              color="primary"
              className="Filter--button"
              onClick={() => setShowFilterDropDown(!showFilterDropDown)}
            >
              <span className="noabbr">
                <FilterList />
                <p>Filtrar</p>
              </span>
              <span className="abbr">
                <FilterList />
              </span>
            </Button>

            <Button
              size="small"
              variant="contained"
              color="primary"
              className="Filter--button"
              onClick={getXlsxSplashes}
            >
              <GetApp />
              <p>Exportar</p>
            </Button>

            <DropDown
              show={showFilterDropDown}
              onPress={() => {
                const filters: SplashFilterParams = { ...splashFilterParams, sortBy: '', sortDir: undefined };
                setWithFilter(true);
                loadData(1, filters, true);
              }}
            >
              <>
                <section className="Filter--section">
                  <p>Buscar por columna</p>
                  <FormControl fullWidth>
                    <Select
                      value={splashFilterParams?.searchBy}
                      labelId="categories"
                      id="categorySelect"
                      variant="outlined"
                      className="Select--filter"
                      onChange={(event) => {
                        let searchBy = '';
                        if (event && event.target && event.target.value) {
                          searchBy = event.target.value as unknown as string;
                        }
                        setSplashFilterParams({ ...splashFilterParams, searchBy });
                      }}
                      displayEmpty
                      defaultValue=""
                      disabled={isLoading}
                    >
                      <MenuItem value="">Todas las categorías</MenuItem>
                      {menuItems.map((item, index) => (
                        <MenuItem key={index} value={`${item.value}`}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </section>
                <section className="Filter--section">
                  <p>Buscar...</p>
                  <input
                    value={splashFilterParams?.search}
                    onKeyPress={(evt) => {
                      if (evt.code === 'Enter') {
                        searchButton.current?.click();
                      }
                      return;
                    }}
                    type="text"
                    onChange={(event) => {
                      setSplashFilterParams({ ...splashFilterParams, search: event.target.value.toLocaleLowerCase() });
                    }}
                    className="Searchbar--input"
                    placeholder={'Buscar…'}
                    disabled={isLoading}
                  />
                </section>
              </>
            </DropDown>
          </div>
          {authData?.user.profileTypeId === ProfileTypes.Admin && (
            <Button
              size="medium"
              variant="contained"
              color="primary"
              className="NewSplash--button"
              onClick={() => history.push(`/private/splashes/create`)}
            >
              <span className="noabbr">Nuevo splash</span>
              <span className="abbr">&#43;</span>
            </Button>
          )}

        </div>
        <TableContainer component={Paper}>
          <Table className="Mui--table">
            <TableHead>
              <TableRow>
                <TableCell className="Mui--tablecell" onClick={() => handleTableCellClick('title')}>
                  <div className="Table--cell">
                    <p>Título</p>
                    <ArrowUpward
                      className={`Arrow--icon ${sortedField.field === 'title' ? `sortedByOrigin ${sortedField.sort}` : ''
                        }`}
                    />
                  </div>
                </TableCell>

                <TableCell className="Mui--tablecell">
                  <div className="Table--cell">
                    <p>Venue</p>
                  </div>
                </TableCell>
                <TableCell>Activo</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {splashPagination?.data ? (
                renderContent(filteredSplashes)
              ) : (
                <TableRow>
                  <TableCell colSpan={7} component="th" scope="row">
                    No hay splashes
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {splashPagination && (
          <TablePagination
            component="div"
            count={splashPagination.total}
            page={splashPagination.currentPage - 1}
            onPageChange={(_, page: number) => {
              loadData(page + 1, splashFilterParams, withFilter);
            }}
            rowsPerPage={splashPagination.perPage}
            rowsPerPageOptions={[]}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from} - ${to} de ${count !== -1 ? count : '0'}`;
            }}
          />
        )}
      </section>
    </>
  );
};
