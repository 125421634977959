import React from 'react';
import { useFormik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import AddOrRemoveTableProps from 'types/forms/AddOrRemoveTableProps';
import { AgeRangeConfigForm } from 'forms/schemas/SplashForm';
import 'styles/components/AddOrRemoveTable.scss';
import { AgeRangeConfig } from 'types/models/Splash';

export default ({ splash, onAddRow, onDeleteRow, onUpdateRow }: AddOrRemoveTableProps): JSX.Element => {
  const Row = ({ config }: { config?: AgeRangeConfig }): JSX.Element => {
    const formik = useFormik({
      initialValues: {
        id: config?.id ?? '',
        fromAge: config?.fromAge ?? '',
        toAge: config?.toAge ?? '',
      },
      validationSchema: AgeRangeConfigForm,
      onSubmit: () => console.log('Alekai'),
    });

    return (
      <>
        <form>
          <div className="row-container">
            <div className="input-container">
              <TextField
                type="number"
                margin="dense"
                id="fromAge"
                name="fromAge"
                label={'Desde'}
                InputProps={{ inputProps: { min: 1 } }}
                value={formik.values.fromAge}
                onChange={formik.handleChange}
              />
              <TextField
                type="number"
                margin="dense"
                id="toAge"
                name="toAge"
                label={'Hasta'}
                InputProps={{ inputProps: { min: formik.values.fromAge } }}
                value={formik.values.toAge}
                error={formik.touched.toAge && Boolean(formik.errors.toAge)}
                helperText={formik.touched.toAge && formik.errors.toAge}
                onChange={formik.handleChange}
              />
            </div>
            <div className="buttons-container">
              {config?.id ? (
                <>
                  <Button variant="outlined" color="primary" onClick={() => onUpdateRow(formik.values)}>
                    Editar
                  </Button>
                  <Button variant="outlined" color="primary" onClick={() => onDeleteRow(formik.values)}>
                    Eliminar
                  </Button>
                </>
              ) : (
                <Button variant="outlined" color="primary" onClick={() => onAddRow(formik.values)}>
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <div className="container">
      <div>
        {splash.ageRangeConfigs.length > 0 && (
          <>
            {splash.ageRangeConfigs.map((config, index) => (
              <Row key={index} config={config} />
            ))}
          </>
        )}
        <Row />
      </div>
    </div>
  );
};
