import * as yup from 'yup';

export const CreateForm = yup.object({
  name: yup.string().required('El nombre del venue es requerido'),
  code: yup
    .string()
    .required('El código del venue es requerido')
    .min(6, 'Se necesitan mínimo 6 caracteres para el código'),
  controllerIp: yup.string().required('La IP del controlador es requerida'),
  countryCode: yup.string().required('El código de país es requerido'),
  controllerUser: yup.string().required('El usuario del controlador es requerido'),
  controllerPassword: yup.string().required('La contraseña del controlador es requerida'),
  useApuestaTotal: yup.boolean().required('El campo de Usa Apuesta Total es requerido'),
  hideFrame: yup.boolean().required('El campo de ocultar el marco del splash es requerido'),
  promotionVideoUrl: yup.mixed().when('hasPromotionVideo', {
    is: true,
    then: yup
      .string()
      .required('La URL del video es requerida')
      .test('no-spaces', 'La URL no debe contener espacios en blanco', (value) => {
        if (value) {
          return !/\s/.test(value);
        }
        return true;
      }),
  }),
  logoSubtitle: yup.mixed().when('hasDiferentLogoLayout', {
    is: true,
    then: yup
      .string()
      .required('El subtítulo del logo es requerido')
      .max(50, 'El subtitulo del logo no debe tener más de 50 caracteres'),
  }),
});
