import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import EditSplashContext from 'contexts/EditSplashContext';
import Splash from 'types/models/Splash';
import ListSplashesPage from 'pages/private/common/ListSplashesPage';
import EditSplashPage from 'pages/private/common/EditSplashPage';
import CreateSplashPage from 'pages/private/CreateSplashPage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedSplash, setSelectedSplash] = React.useState<Splash | null>(null);

  return (
    <EditSplashContext.Provider value={{ selectedSplash, setSelectedSplash }}>
      <Switch>
        <Route exact={true} path={`${match.url}`} component={ListSplashesPage} />
        <Route exact={true} path={`${match.url}/:splashId/edit`} component={EditSplashPage} />
        <Route exact={true} path={`${match.url}/create`} component={CreateSplashPage} />
        <Redirect to="/private/welcome" />
      </Switch>
    </EditSplashContext.Provider>
  );
};
