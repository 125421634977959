import * as yup from 'yup';

export default (): any =>
  yup.object().shape({
    email: yup.string().email('Invalid email address').required('El Email es requerido'),
    password: yup
      .string()
      .required('La contraseña es requerida')
      .min(6, 'La contraseña debe tener más de 6 caracteres')
      .max(32, 'La contraseña debe tener menos de 32 caracteres')
      .matches(/^\S*$/, 'La contraseña no puede tener espacios'),
  });
