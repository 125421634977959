import React from 'react';
import { useHistory } from 'react-router-dom';
import VenueForm from 'forms/VenueForm';
import EditVenueContext from 'contexts/EditVenueContext';
import { getVenue } from 'services/venueServices';
import Venue from 'types/models/Venue';
import AppContext from 'contexts/AppContext';

export default (): JSX.Element => {
  const history = useHistory();
  const { setIsLoading } = React.useContext(AppContext);
  const { selectedVenue } = React.useContext(EditVenueContext);
  const [completeVenue, setCompleteVenue] = React.useState<Venue | null>(null);

  const load = React.useCallback(async (id: string) => {
    setIsLoading(true);
    const venue = await getVenue(id);
    if (!venue) {
      setIsLoading(false);
      history.push('/private/venues');
      return;
    }
    setCompleteVenue(venue);
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    if (!selectedVenue) {
      history.push('/private/common/venues');
    } else {
      load(selectedVenue.id);
    }
  }, []);

  if (!completeVenue) return <></>;
  return (
    <VenueForm
      venue={completeVenue}
    />
  );
};
