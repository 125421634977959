import { Button } from '@material-ui/core';
import type { DropDownProps } from 'types/components/DropDownProps';
import 'styles/components/DropDown.scss';

export default ({ show, children, onPress }: DropDownProps): JSX.Element => {
  return (
    <div className={`Filter--panel ${show ? 'show' : 'hide'}`}>
      {children}
      <Button size="small" variant="contained" color="primary" className="Filters--button" onClick={onPress}>
        Aplicar
      </Button>
    </div>
  );
};
