import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import EditVenueContext from 'contexts/EditVenueContext';
import Venue from 'types/models/Venue';
import ListVenuesPage from 'pages/private/common/ListVenuesPage';
import EditVenuePage from 'pages/private/common/EditVenuePage';
import CreateVenuePage from 'pages/private/CreateVenuePage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedVenue, setSelectedVenue] = React.useState<Venue | null>(null);

  return (
    <EditVenueContext.Provider value={{ selectedVenue, setSelectedVenue }}>
      <Switch>
        <Route exact={true} path={`${match.url}`} component={ListVenuesPage} />
        <Route exact={true} path={`${match.url}/:venueId/edit`} component={EditVenuePage} />
        <Route exact={true} path={`${match.url}/create`} component={CreateVenuePage} />
        <Redirect to="/private/welcome" />
      </Switch>
    </EditVenueContext.Provider>
  );
};
