import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, FormControlLabel, InputAdornment, Switch, TextField } from '@material-ui/core';
import {
  updateSplash,
  storeSplash,
  storeAgeRangeConfig,
  destroyAgeRangeConfig,
  updateAgeRangeConfig,
} from 'services/splashServices';
import ConfirmDialog from 'components/ConfirmDialog';
import AppContext from 'contexts/AppContext';
import Splash, { AgeRangeConfig } from 'types/models/Splash';
import SplashFormProps from 'types/forms/SplashFormProps';
import { CreateForm } from 'forms/schemas/SplashForm';
import 'styles/components/SplashForm.scss';
import ImageInput from 'components/ImageInput';
import SelectField from 'components/SelectField';
import Venue from 'types/models/Venue';
import { getVenues } from 'services/venueServices';
import AddOrRemoveTable from 'components/AddOrRemoveTable';

export default ({ splash, setSplash }: SplashFormProps): JSX.Element => {
  const history = useHistory();
  const { setIsLoading } = React.useContext(AppContext);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [venues, setVenues] = React.useState<Venue[]>([]);
  const backgroundMainImage = splash?.backgroundMainImage;
  const backgroundHeaderImage = splash?.backgroundHeaderImage;
  const backgroundFooterImage = splash?.backgroundFooterImage;
  const promotionBannerImage = splash?.promotionBannerImage;

  React.useEffect(() => {
    const getAllVenues = async () => {
      const allVenues = await getVenues();
      setVenues(allVenues);
    };
    getAllVenues();
  }, []);

  const venuesForSelect = (venues: Venue[]) => {
    return venues.map((venue) => {
      return {
        label: venue.name,
        value: venue.id,
      };
    });
  };

  const submitTitle = (function () {
    return splash ? 'Editar' : 'Crear';
  })();

  const choseValidationSchema = () => {
    return CreateForm;
  };

  const formik = useFormik({
    initialValues: {
      id: splash?.id ?? '',
      title: splash?.title ?? '',
      titleColor: splash?.titleColor ?? '',
      headerColor: splash?.headerColor ?? '',
      backgroundColor: splash?.backgroundColor ?? '',
      footerColor: splash?.footerColor ?? '',
      buttonColor: splash?.buttonColor ?? '',
      buttonTextColor: splash?.buttonTextColor ?? '',
      venueId: splash?.venueId ?? '',
      active: splash?.active ?? true,
      urlToWeb: splash?.urlToWeb ?? '',
      apuestaTotalClientUrl: splash?.apuestaTotalClientUrl ?? '',
      apuestaTotalNoClientUrl: splash?.apuestaTotalNoClientUrl ?? '',
      conectionTime: splash?.conectionTime ?? '',
      // logo: logo?.data ?? null,
      // promotionImage: promotionImage?.data ?? null,
      promotionBannerImage: promotionBannerImage?.data ?? null,
      showEmail: splash?.showEmail ?? false,
      emailIsRequired: splash?.emailIsRequired ?? false,
      showPhone: splash?.showPhone ?? false,
      phoneIsRequired: splash?.phoneIsRequired ?? false,
      showIdentificationNumber: splash?.showIdentificationNumber ?? false,
      identificationNumberIsRequired: splash?.identificationNumberIsRequired ?? false,
      identificationNumberLabel: splash?.identificationNumberLabel ?? '',
      showFullname: splash?.showFullname ?? false,
      fullnameIsRequired: splash?.fullnameIsRequired ?? false,
      showAgeRange: splash?.showAgeRange ?? false,
      ageRangeIsRequired: splash?.ageRangeIsRequired ?? false,
      betWidgetUrl: splash?.betWidgetUrl ?? '',
      backgroundMainImage: backgroundMainImage?.data ?? null,
      backgroundHeaderImage: backgroundHeaderImage?.data ?? null,
      backgroundFooterImage: backgroundFooterImage?.data ?? null,
    },
    validationSchema: choseValidationSchema(),
    onSubmit: () => setOpenDialog(true),
  });

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    setIsLoading(true);

    try {
      let result: Splash | null = null;
      if (splash) {
        const newSplash = {
          ...splash,
          ...formik.values,
        };

        result = await updateSplash(splash.id, splashToFormData(newSplash));
      } else {
        const newSplash = formik.values;
        result = await storeSplash(splashToFormData(newSplash));
      }

      if (result) {
        history.go(-1);
      }
    } catch (error) {
      console.log('Error en onConfirm', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      formik.setSubmitting(false);
    }
  };

  const splashToFormData = (request: any): FormData => {
    const formData = new FormData();
    for (const key in request) {
      if (request[key] !== undefined) {
        const value = request[key];
        if (key === 'logo' || key === 'promotionImage' || key === 'promotionBannerImage') {
          const file = formik.values[key]
          formData.append(key, file as unknown as File);
        } else {
          if (typeof value === 'boolean') {
            formData.set(key, Boolean(value) ? '1' : '0');
          }else if(value == null){
            formData.append(key, '');
          }else {
            formData.append(key, value);
          }
        }
      }
    }

    return formData;
  };

  const onDeleteAgeRangeConfigRow = async (ageRangeConfig: AgeRangeConfig) => {
    if (!splash || !setSplash) return;
    const ageRangeConfigResult = await destroyAgeRangeConfig(ageRangeConfig.id);
    if (ageRangeConfigResult !== null) {
      splash.ageRangeConfigs = splash.ageRangeConfigs.filter((config) => config.id !== ageRangeConfigResult.id);
      setSplash({ ...splash });
    }
  };

  const onAddAgeRangeConfigRow = async (ageRangeConfig: AgeRangeConfig): Promise<void> => {
    if (!splash || !setSplash) return;
    const body = {
      ...ageRangeConfig,
      splashId: splash.id,
    };

    const ageRangeConfigResult = await storeAgeRangeConfig(body);
    if (ageRangeConfigResult !== null) {
      splash.ageRangeConfigs.push(ageRangeConfigResult);
      setSplash({ ...splash });
    }
  };

  const onUpdateAgeRangeConfigRow = async (ageRangeConfig: AgeRangeConfig): Promise<void> => {
    if (!splash || !setSplash) return;
    const body = {
      ...ageRangeConfig,
      splashId: splash.id,
    };

    const ageRangeConfigResult = await updateAgeRangeConfig(body);
    if (ageRangeConfigResult !== null) {
      const configIndex = splash.ageRangeConfigs.findIndex((config) => config.id === ageRangeConfigResult.id);
      if (configIndex !== null) {
        splash.ageRangeConfigs[configIndex] = ageRangeConfigResult;
      }
    }
  };

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <h2>Información básica</h2>
        <h3>Datos del splash</h3>
        <TextField
          margin="dense"
          fullWidth
          id="title"
          name="title"
          label={'Título'}
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="titleColor"
          name="titleColor"
          label={'Color del título'}
          value={formik.values.titleColor}
          onChange={formik.handleChange}
          error={formik.touched.titleColor && Boolean(formik.errors.titleColor)}
          helperText={formik.touched.titleColor && formik.errors.titleColor}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="headerColor"
          name="headerColor"
          label={'Color del header'}
          value={formik.values.headerColor}
          onChange={formik.handleChange}
          error={formik.touched.headerColor && Boolean(formik.errors.headerColor)}
          helperText={formik.touched.headerColor && formik.errors.headerColor}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="backgroundColor"
          name="backgroundColor"
          label={'Color del fondo'}
          value={formik.values.backgroundColor}
          onChange={formik.handleChange}
          error={formik.touched.backgroundColor && Boolean(formik.errors.backgroundColor)}
          helperText={formik.touched.backgroundColor && formik.errors.backgroundColor}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="footerColor"
          name="footerColor"
          label={'Color del footer'}
          value={formik.values.footerColor}
          onChange={formik.handleChange}
          error={formik.touched.footerColor && Boolean(formik.errors.footerColor)}
          helperText={formik.touched.footerColor && formik.errors.footerColor}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="buttonColor"
          name="buttonColor"
          label={'Color del botón'}
          value={formik.values.buttonColor}
          onChange={formik.handleChange}
          error={formik.touched.buttonColor && Boolean(formik.errors.buttonColor)}
          helperText={formik.touched.buttonColor && formik.errors.buttonColor}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="buttonTextColor"
          name="buttonTextColor"
          label={'Color de texto de botón'}
          value={formik.values.buttonTextColor}
          onChange={formik.handleChange}
          error={formik.touched.buttonTextColor && Boolean(formik.errors.buttonTextColor)}
          helperText={formik.touched.buttonTextColor && formik.errors.buttonTextColor}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <SelectField
          name="venueId"
          label={'Seleccionar el venue'}
          placeholder={'Seleccionar el venue'}
          value={formik.values.venueId}
          options={venuesForSelect(venues)}
          isMulti={false}
          setFieldValue={formik.setFieldValue}
          onBlur={formik.handleBlur}
          touched={formik.touched.venueId}
          error={formik.errors.venueId}
          isClearable={true}
          backspaceRemovesValue={true}
          isDisabled={formik.isSubmitting}
          isSearchable={true}
        />
        <TextField
          margin="dense"
          fullWidth
          id="conectionTime"
          name="conectionTime"
          label={'Tiempo de conexión'}
          value={formik.values.conectionTime}
          InputProps={{
            endAdornment: <InputAdornment position="end">Minutos</InputAdornment>,
          }}
          onChange={formik.handleChange}
          error={formik.touched.conectionTime && Boolean(formik.errors.conectionTime)}
          helperText={formik.touched.conectionTime && formik.errors.conectionTime}
          disabled={formik.isSubmitting}
        />
        <FormControlLabel
          id="active"
          name="active"
          control={<Switch checked={formik.values.active} onChange={formik.handleChange} />}
          label={'Activo'}
          disabled={formik.isSubmitting}
        />
        <div>
          <FormControlLabel
            id="showEmail"
            name="showEmail"
            control={<Switch checked={formik.values.showEmail} onChange={formik.handleChange} />}
            label={'Mostrar campo de email'}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            id="emailIsRequired"
            name="emailIsRequired"
            control={<Switch checked={formik.values.emailIsRequired} onChange={formik.handleChange} />}
            label={'Es obligatorio'}
            disabled={formik.isSubmitting}
          />
        </div>

        <div>
          <FormControlLabel
            id="showPhone"
            name="showPhone"
            control={<Switch checked={formik.values.showPhone} onChange={formik.handleChange} />}
            label={'Mostrar campo de teléfono'}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            id="phoneIsRequired"
            name="phoneIsRequired"
            control={<Switch checked={formik.values.phoneIsRequired} onChange={formik.handleChange} />}
            label={'Es obligatorio'}
            disabled={formik.isSubmitting}
          />
        </div>

        <div>
          <FormControlLabel
            id="showIdentificationNumber"
            name="showIdentificationNumber"
            control={<Switch checked={formik.values.showIdentificationNumber} onChange={formik.handleChange} />}
            label={'Mostrar campo de N° de identificación'}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            id="identificationNumberIsRequired"
            name="identificationNumberIsRequired"
            control={<Switch checked={formik.values.identificationNumberIsRequired} onChange={formik.handleChange} />}
            label={'Es obligatorio'}
            disabled={formik.isSubmitting}
          />
          <TextField
            margin="dense"
            fullWidth
            id="identificationNumberLabel"
            name="identificationNumberLabel"
            label={'Label del campo'}
            value={formik.values.identificationNumberLabel}
            onChange={formik.handleChange}
            error={formik.touched.identificationNumberLabel && Boolean(formik.errors.identificationNumberLabel)}
            helperText={formik.touched.identificationNumberLabel && formik.errors.identificationNumberLabel}
            inputProps={{ readOnly: formik.isSubmitting }}
          />
        </div>
        <div>
          <FormControlLabel
            id="showFullname"
            name="showFullname"
            control={<Switch checked={formik.values.showFullname} onChange={formik.handleChange} />}
            label={'Mostrar campo de Nombre Completo'}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            id="fullnameIsRequired"
            name="fullnameIsRequired"
            control={<Switch checked={formik.values.fullnameIsRequired} onChange={formik.handleChange} />}
            label={'Es obligatorio'}
            disabled={formik.isSubmitting}
          />
        </div>
        <div>
          <FormControlLabel
            id="showAgeRange"
            name="showAgeRange"
            control={<Switch checked={formik.values.showAgeRange} onChange={formik.handleChange} />}
            label={'Mostrar campo de rango de edad'}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            id="ageRangeIsRequired"
            name="ageRangeIsRequired"
            control={<Switch checked={formik.values.ageRangeIsRequired} onChange={formik.handleChange} />}
            label={'Es obligatorio'}
            disabled={formik.isSubmitting}
          />
        </div>

        {splash && formik.values.showAgeRange && (
          <AddOrRemoveTable
            splash={splash}
            onDeleteRow={onDeleteAgeRangeConfigRow}
            onAddRow={onAddAgeRangeConfigRow}
            onUpdateRow={onUpdateAgeRangeConfigRow}
          />
        )}
        <TextField
          margin="dense"
          fullWidth
          id="urlToWeb"
          name="urlToWeb"
          label={'Url de la web a redireccionar'}
          value={formik.values.urlToWeb}
          onChange={formik.handleChange}
          error={formik.touched.urlToWeb && Boolean(formik.errors.urlToWeb)}
          helperText={formik.touched.urlToWeb && formik.errors.urlToWeb}
          inputProps={{ readOnly: formik.isSubmitting }}
        />

        <TextField
          margin="dense"
          fullWidth
          id="apuestaTotalClientUrl"
          name="apuestaTotalClientUrl"
          label={'Url de la web a redireccionar a clientes'}
          value={formik.values.apuestaTotalClientUrl}
          onChange={formik.handleChange}
          error={formik.touched.apuestaTotalClientUrl && Boolean(formik.errors.apuestaTotalClientUrl)}
          helperText={formik.touched.apuestaTotalClientUrl && formik.errors.apuestaTotalClientUrl}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="apuestaTotalNoClientUrl"
          name="apuestaTotalNoClientUrl"
          label={'Url de la web a redireccionar a los NO clientes'}
          value={formik.values.apuestaTotalNoClientUrl}
          onChange={formik.handleChange}
          error={formik.touched.apuestaTotalNoClientUrl && Boolean(formik.errors.apuestaTotalNoClientUrl)}
          helperText={formik.touched.apuestaTotalNoClientUrl && formik.errors.apuestaTotalNoClientUrl}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="betWidgetUrl"
          name="betWidgetUrl"
          label={'Url del Widget de JugaBet'}
          value={formik.values.betWidgetUrl}
          onChange={formik.handleChange}
          error={formik.touched.betWidgetUrl && Boolean(formik.errors.betWidgetUrl)}
          helperText={formik.touched.betWidgetUrl && formik.errors.betWidgetUrl}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <ImageInput
          label={`${formik.values.promotionBannerImage || promotionBannerImage ? 'Editar' : 'Crear'} banner`}
          name="promotionBannerImage"
          isSubmitting={formik.isSubmitting}
          value={formik.values.promotionBannerImage}
          setFieldValue={formik.setFieldValue}
          idealSize={{ width: 500, height: 1024 }}
          touched={formik.touched.promotionBannerImage}
          errors={formik.errors.promotionBannerImage}
          preview={promotionBannerImage?.preview}
        />

        <ImageInput
          label={`${formik.values.backgroundMainImage || backgroundMainImage ? 'Editar' : 'Crear'} imagen de fondo principal`}
          name="backgroundMainImage"
          isSubmitting={formik.isSubmitting}
          value={formik.values.backgroundMainImage}
          setFieldValue={formik.setFieldValue}
          idealSize={{ width: 1284, height: 2778 }}
          touched={formik.touched.backgroundMainImage}
          errors={formik.errors.backgroundMainImage}
          preview={backgroundMainImage?.preview}
          canDelete
        />
        <ImageInput
          label={`${formik.values.backgroundHeaderImage || backgroundHeaderImage ? 'Editar' : 'Crear'} imagen de fondo header`}
          name="backgroundHeaderImage"
          isSubmitting={formik.isSubmitting}
          value={formik.values.backgroundHeaderImage}
          setFieldValue={formik.setFieldValue}
          idealSize={{ width: 1024, height: 100 }}
          touched={formik.touched.backgroundHeaderImage}
          errors={formik.errors.backgroundHeaderImage}
          preview={backgroundHeaderImage?.preview}
          canDelete
        />
        <ImageInput
          label={`${formik.values.backgroundFooterImage || backgroundFooterImage ? 'Editar' : 'Crear'} imagen de fondo footer`}
          name="backgroundFooterImage"
          isSubmitting={formik.isSubmitting}
          value={formik.values.backgroundFooterImage}
          setFieldValue={formik.setFieldValue}
          idealSize={{ width: 1024, height: 100 }}
          touched={formik.touched.backgroundFooterImage}
          errors={formik.errors.backgroundFooterImage}
          preview={backgroundFooterImage?.preview}
          canDelete
        />
        <div className="footer Footer--btn">
          <Button
            variant="contained"
            className="Footer--submit"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {submitTitle}
          </Button>
        </div>
      </form>
    </>
  );
};
