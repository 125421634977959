import * as React from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import type SearchBarProps from 'types/components/SearchBarProps';
import { Search } from '@material-ui/icons';
import 'styles/components/SearchBar.scss';

export default ({
  onPress,
  handleInputChange,
  handleSelectChange,
  disabled,
  menuItems,
}: SearchBarProps): JSX.Element => {
  const searchButton = React.useRef<HTMLButtonElement>(null);

  return (
    <div className="Search--container">
      <FormControl className="Search--formcontrol">
        <InputLabel shrink id="categories">
          Categorías
        </InputLabel>
        <Select
          labelId="categories"
          id="categorySelect"
          variant="outlined"
          className="Seach--filter-select"
          onChange={handleSelectChange}
          displayEmpty
          defaultValue=""
          disabled={disabled}
        >
          <MenuItem value="">Todas las categorías</MenuItem>
          {menuItems.map((item, index) => (
            <MenuItem key={index} value={`${item.value}`}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <input
        onKeyPress={(evt) => {
          if (evt.code === 'Enter') {
            searchButton.current?.click();
          }
          return;
        }}
        type="text"
        onChange={handleInputChange}
        className="Searchbar--input"
        placeholder={'Buscar…'}
        disabled={disabled}
      />
      <Button
        ref={searchButton}
        size="medium"
        variant="contained"
        className="Searchbar--button"
        color="primary"
        onClick={onPress}
        disabled={disabled}
      >
        <Search viewBox="3 3 18 17" className="Search--icon abbr" />
      </Button>
    </div>
  );
};
