import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

export const parseDateTime = (date: Date): string => {
  return moment(date).format('DD [de] MMMM HH:mm');
};

export const parseDateTimeLumen = (date: Date): string => {
  if (!date) return "";
  const formatedDate= moment.utc(date).format('yyyy-MM-DD HH:mm:ss');
  return formatedDate;
};

export const parseDateToFormik = (date: Date): string => {
  return moment(date).format('yyyy-MM-DD[T]HH:mm');
};
