import React from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { Button, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { updateVenue, storeVenue } from 'services/venueServices';
import ConfirmDialog from 'components/ConfirmDialog';
import AppContext from 'contexts/AppContext';
import Venue from 'types/models/Venue';
import VenueFormProps from 'types/forms/VenueFormProps';
import { CreateForm } from 'forms/schemas/VenueForm';
import 'styles/components/VenueForm.scss';
import SelectField from 'components/SelectField';

export default ({ venue }: VenueFormProps): JSX.Element => {
  const history = useHistory();
  const { setIsLoading } = React.useContext(AppContext);
  const [openDialog, setOpenDialog] = React.useState(false);

  const submitTitle = (function () {
    return venue ? 'Editar' : 'Crear';
  })();

  const choseValidationSchema = () => {
    return CreateForm;
  };

  const formik = useFormik({
    initialValues: {
      id: venue?.id ?? '',
      name: venue?.name ?? '',
      code: venue?.code ?? '',
      termsAndConditionsLink: venue?.termsAndConditionsLink ?? '',
      politicsUrl: venue?.politicsUrl ?? '',
      controllerService: venue?.controllerService ?? '',
      controllerIp: venue?.controllerIp ?? '',
      controllerUser: venue?.controllerUser ?? '',
      controllerPassword: venue?.controllerPassword ?? '',
      useApuestaTotal: venue?.useApuestaTotal ?? false,
      hideFrame: venue?.hideFrame ?? false,
      hasPromotionVideo: venue?.hasPromotionVideo ?? false,
      promotionVideoUrl: venue?.promotionVideoUrl ?? '',
      countryCode: venue?.countryCode ?? '',
      logoSubtitle: venue?.logoSubtitle ?? '',
      factorealContactListName: venue?.factorealContactListName ?? '',
    },
    validationSchema: choseValidationSchema(),
    onSubmit: () => {
      setOpenDialog(true);
    },
  });

  const onCancel = (): void => {
    formik.setSubmitting(false);
  };

  const onConfirm = async (): Promise<void> => {
    setIsLoading(true);

    try {
      let result: Venue | null = null;
      if (venue) {
        const newVenue = {
          ...venue,
          ...formik.values,
          promotionVideoUrl: formik.values.hasPromotionVideo ? formik.values.promotionVideoUrl : undefined,
        };
        result = await updateVenue(venue.id, venueToFormData(newVenue));
      } else {
        const newVenue = formik.values;
        result = await storeVenue(venueToFormData(newVenue));
      }

      if (result) {
        history.go(-1);
      }
    } catch (error) {
      console.log('Error en onConfirm', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      formik.setSubmitting(false);
    }
  };
  const venueToFormData = (request: any): FormData => {
    const formData = new FormData();
    for (const key in request) {
      if (request[key] !== undefined) {
        const value = request[key];
        if (typeof value === 'boolean') {
          formData.set(key, Boolean(value) ? '1' : '0');
        } else {
          formData.append(key, value);
        }
      }
    }

    return formData;
  };
  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <form onSubmit={formik.handleSubmit}>
        <h2>Información básica</h2>
        <h3>Datos del venue</h3>
        <TextField
          margin="dense"
          fullWidth
          id="name"
          name="name"
          label={'Nombre'}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="code"
          name="code"
          label={'Código'}
          value={formik.values.code}
          onChange={formik.handleChange}
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="termsAndConditionsLink"
          name="termsAndConditionsLink"
          label={'Link de términos y condiciones'}
          value={formik.values.termsAndConditionsLink}
          onChange={formik.handleChange}
          error={formik.touched.termsAndConditionsLink && Boolean(formik.errors.termsAndConditionsLink)}
          helperText={formik.touched.termsAndConditionsLink && formik.errors.termsAndConditionsLink}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="politicsUrl"
          name="politicsUrl"
          label={'Link de políticas de privacidad'}
          value={formik.values.politicsUrl}
          onChange={formik.handleChange}
          error={formik.touched.politicsUrl && Boolean(formik.errors.politicsUrl)}
          helperText={formik.touched.politicsUrl && formik.errors.politicsUrl}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="countryCode"
          name="countryCode"
          label={'Código de teléfono del país'}
          value={formik.values.countryCode}
          onChange={formik.handleChange}
          error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
          helperText={formik.touched.countryCode && formik.errors.countryCode}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="factorealContactListName"
          name="factorealContactListName"
          label={'Nombre de la lista de contactos para Factoreal'}
          value={formik.values.factorealContactListName}
          onChange={formik.handleChange}
          error={formik.touched.factorealContactListName && Boolean(formik.errors.factorealContactListName)}
          helperText={formik.touched.factorealContactListName && formik.errors.factorealContactListName}
          inputProps={{ readOnly: formik.isSubmitting }}
        />

        <SelectField
          backspaceRemovesValue
          isClearable
          isDisabled={false}
          isMulti={false}
          touched={false}
          name="controllerService"
          label="Seleccionar el servicio"
          placeholder="Seleccionar el servicio"
          isSearchable={false}
          onBlur={formik.handleBlur}
          setFieldValue={formik.setFieldValue}
          value={formik.values.controllerService}
          error={formik.touched.controllerService && Boolean(formik.errors.controllerService)}
          options={[
            { value: 'ruckus', label: 'Ruckus' },
            { value: 'meraki', label: 'Meraki' },
          ]}
        />

        <TextField
          margin="dense"
          fullWidth
          id="controllerIp"
          name="controllerIp"
          label={'IP del controlador'}
          value={formik.values.controllerIp}
          onChange={formik.handleChange}
          error={formik.touched.controllerIp && Boolean(formik.errors.controllerIp)}
          helperText={formik.touched.controllerIp && formik.errors.controllerIp}
          inputProps={{ readOnly: formik.isSubmitting }}
        />
        <TextField
          margin="dense"
          fullWidth
          id="controllerUser"
          name="controllerUser"
          label={'User del controlador'}
          value={formik.values.controllerUser}
          onChange={formik.handleChange}
          error={formik.touched.controllerUser && Boolean(formik.errors.controllerUser)}
          helperText={formik.touched.controllerUser && formik.errors.controllerUser}
          inputProps={{ readOnly: formik.isSubmitting }}
        />

        <TextField
          margin="dense"
          fullWidth
          id="controllerIp"
          name="controllerPassword"
          label={'Contraseña del controlador'}
          value={formik.values.controllerPassword}
          onChange={formik.handleChange}
          error={formik.touched.controllerPassword && Boolean(formik.errors.controllerPassword)}
          helperText={formik.touched.controllerPassword && formik.errors.controllerPassword}
          inputProps={{ readOnly: formik.isSubmitting }}
        />

        <FormControlLabel
          id="useApuestaTotal"
          name="useApuestaTotal"
          control={<Switch checked={formik.values.useApuestaTotal} onChange={formik.handleChange} />}
          label={'Usa Apuesta Total'}
          disabled={formik.isSubmitting}
        />
        <FormControlLabel
          id="hasPromotionVideo"
          name="hasPromotionVideo"
          control={<Switch checked={formik.values.hasPromotionVideo} onChange={formik.handleChange} />}
          label={'Tiene video promoción'}
          disabled={formik.isSubmitting}
        />
        <FormControlLabel
          id="hideFrame"
          name="hideFrame"
          control={<Switch checked={formik.values.hideFrame} onChange={formik.handleChange} />}
          label={'¿Necesita ocultar el marco del splash?'}
          disabled={formik.isSubmitting}
        />

        {formik.values.hasPromotionVideo && (
          <TextField
            margin="dense"
            fullWidth
            id="promotionVideoUrl"
            name="promotionVideoUrl"
            label={'Url del video'}
            value={formik.values.promotionVideoUrl}
            onChange={formik.handleChange}
            error={formik.touched.promotionVideoUrl && Boolean(formik.errors.promotionVideoUrl)}
            helperText={formik.touched.promotionVideoUrl && formik.errors.promotionVideoUrl}
            inputProps={{ readOnly: formik.isSubmitting }}
          />
        )}

        <div className="footer Footer--btn">
          <Button
            variant="contained"
            className="Footer--submit"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {submitTitle}
          </Button>
        </div>
      </form>
    </>
  );
};
