import React from 'react';
import Select from 'react-select';
import { Box, Typography } from '@material-ui/core';
import SelectFieldProps from 'types/components/SelectFieldProps';
import SelectedFieldOption from 'types/models/SelectFieldOption';
import 'styles/components/SelectField.scss';

export default ({
  label,
  name,
  placeholder,
  value,
  options,
  isMulti,
  setFieldValue,
  onBlur,
  touched,
  error,
  isClearable,
  backspaceRemovesValue,
  isDisabled,
  isSearchable,
}: SelectFieldProps): JSX.Element => {
  const onChange = (option: SelectedFieldOption | SelectedFieldOption[]) => {
    setFieldValue(
      name,
      isMulti
        ? (option as SelectedFieldOption[]).map((item: SelectedFieldOption) => item.value)
        : (option as SelectedFieldOption)?.value,
    );
  };
  const getValue = () => {
    if (options && value) {
      return isMulti
        ? options.filter((option) => value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === value);
    } else {
      return isMulti ? [] : null;
    }
  };
  return (
    <Box className="select-field-container">
      <Typography>{label}</Typography>
      <Select
        name={name}
        placeholder={placeholder}
        options={options}
        value={getValue()}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        error={error}
        isMulti={isMulti}
        isClearable={isClearable}
        backspaceRemovesValue={backspaceRemovesValue}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
      />
      {touched && error && <Typography className="field-error">{error}</Typography>}
    </Box>
  );
};
