import React from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import Navigation from './navigation/Navigation';
import './App.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#195C61',
    },
  },
});

export default (): JSX.Element => {
  return (
    <MuiThemeProvider theme={theme}>
      <Navigation />
    </MuiThemeProvider>
  );
};
