import * as yup from 'yup';

export const UserFormEdit = (): any => {
  return yup.object({
    fullname: yup.string().required('Campo requerido'),
    email: yup.string().required('Campo requerido'),
    password: yup
      .string()
      .min(6, 'La contraseña debe tener más de 6 caracteres')
      .max(32, 'La contraseña debe tener menos de 32 caracteres')
      .matches(/^\S*$/, 'La contraseña no puede tener espacios'),
    profileTypeId: yup.number().required('El tipo de usuario es requerido'),
    venueId: yup.string().required('El venue es requerido'),
  });
};
export const UserFormCreate = (): any => {
  return yup.object({
    fullname: yup.string().required('Campo requerido'),
    email: yup.string().required('Campo requerido'),
    password: yup
      .string()
      .required('La contraseña es requerida')
      .min(6, 'La contraseña debe tener más de 6 caracteres')
      .max(32, 'La contraseña debe tener menos de 32 caracteres')
      .matches(/^\S*$/, 'La contraseña no puede tener espacios'),
    venueId: yup.string().required('El venue es requerido'),
    profileTypeId: yup.number().required('El tipo de usuario es requerido'),
  });
};
