import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { Menu as MenuIcon, AccountCircle } from '@material-ui/icons';
import AppContext from 'contexts/AppContext';
import 'styles/components/AppHeader.scss';
import ProfileTypes from 'types/enums/ProfileTypes';

export default (): JSX.Element => {
  const history = useHistory();
  const { authData, setAuthData } = React.useContext(AppContext);
  const [toggleDrawer, setToggleDrawer] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToPage = (route: string) => {
    history.push(route);
    setToggleDrawer(false);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <>
          {authData && (
            <>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setToggleDrawer(true);
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" className="app-bar-title">
                Menu
              </Typography>
              <div>
                <IconButton onClick={handleMenu} color="inherit" edge="end">
                  <AccountCircle />
                </IconButton>
                <Menu id="menu-appbar" anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem
                    onClick={() => {
                      goToPage('/private/common/change-password');
                    }}
                  >
                    CAMBIAR CONTRASEÑA
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAuthData(null);
                      localStorage.removeItem('auth-data');
                      handleClose();
                      return <Redirect to="/public/login" />;
                    }}
                  >
                    CERRAR SESIÓN
                  </MenuItem>
                </Menu>
              </div>
            </>
          )}
        </>
      </Toolbar>
      <Drawer
        anchor="left"
        open={toggleDrawer}
        onClose={() => {
          setToggleDrawer(false);
        }}
      >
        <Button
          onClick={() => {
            goToPage('/private/welcome');
          }}
        >
          Inicio
        </Button>

        {authData?.user.profileTypeId === ProfileTypes.Admin && (
          <Button
            onClick={() => {
              goToPage('/private/venues');
            }}
          >
            Venues
          </Button>
        )}

        {(authData?.user.showUserSection || authData?.user.showUserSplashSection || authData?.user.profileTypeId === ProfileTypes.Admin) && (
          <Button
            onClick={() => {
              goToPage('/private/users');
            }}
          >
            Usuarios
          </Button>
        )}

        {authData?.user.profileTypeId === ProfileTypes.Admin && (
          <Button
            onClick={() => {
              goToPage('/private/user-editors');
            }}
          >
            Editores de venue
          </Button>
        )}


        {(authData?.user.showSplashSection || authData?.user.showUserSplashSection || authData?.user.profileTypeId === ProfileTypes.Admin) && (
          <Button
            onClick={() => {
              goToPage('/private/splashes');
            }}
          >
            Splashes
          </Button>
        )}
      </Drawer>
    </AppBar>
  );
};
