import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import WelcomePage from 'pages/private/WelcomePage';
// import AdminRoutes from './AdminRoutes';
import VenueContextRoute from './VenueContextRoute';
import SplashContextRoute from './SplashContextRoute';

import UserContextRoute from './UserContextRoute';
import UserEditorContextRoute from './UserEditorContextRoute';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const { authData } = React.useContext(AppContext);

  if (!authData) {
    return <Redirect to="/public/login" />;
  }

  return (
    <Switch>
      <Route exact path={`${match.url}/welcome`} component={WelcomePage} />
      {/* Admin Routes */}
      {/* <Route path={`${match.url}/admin`} component={AdminRoutes} /> */}
      <Route path={`${match.url}/venues`} component={VenueContextRoute} />
      <Route path={`${match.url}/splashes`} component={SplashContextRoute} />
      <Route path={`${match.url}/users`} component={UserContextRoute} />
      <Route path={`${match.url}/user-editors`} component={UserEditorContextRoute} />
      <Redirect to="/private/welcome" />
    </Switch>
  );
};
