/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance, { axiosMultipart } from './axios';
import PaginationData from 'types/models/PaginationData';
import Venue from 'types/models/Venue';
import { VenueFilterParams } from 'types/models/filters';
import { joinUrlParams } from 'utils/joinUrlParams';

export const getVenues = async (): Promise<Venue[]> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'venues',
    });
    return parseVenues(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getVenuesWithPagination = async (
  page = 1,
  venueFilterParams?: VenueFilterParams | null,
  withFilter = false,
): Promise<PaginationData<Venue> | null> => {
  let url = `venues?page=${page ?? 1}&withFilter=${withFilter.toString()}`;

  if (venueFilterParams) {
    url = joinUrlParams(venueFilterParams, url);
  }

  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseVenues(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateVenue = async (venueId: string, venue: FormData): Promise<Venue | null> => {
  try {
    const { data } = await axiosMultipart({
      method: 'post',
      url: `venues/${venueId}`,
      data: venue,
    });

    return parseVenue(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeVenue = async (venue: FormData): Promise<Venue | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `venues`,
      data: venue,
    });
    return parseVenue(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const destroyVenue = async (id: string): Promise<Venue | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `venues/${id}`,
    });
    return parseVenue(data);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const approveVenue = async (id: string): Promise<Venue | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `venues/${id}/approve`,
    });
    return parseVenue(data);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const declineVenue = async (id: string): Promise<Venue | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `venues/${id}/decline`,
    });
    return parseVenue(data);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getVenue = async (venueId: string): Promise<Venue | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `venues/${venueId}`,
    });
    console.log('recibo de req', data);

    return parseVenue(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const exportVenues = async (
  venueFilterParams?: VenueFilterParams | null,
  withFilter = false,
): Promise<Blob | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'venues/export',
      responseType: 'blob',
      data: {
        ...venueFilterParams,
        withFilter: String(withFilter),
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const parseVenues = (data: any): Venue[] => {
  return data.map((item: any) => parseVenue(item));
};

export const parseVenue = (venue: any): Venue => ({
  id: venue.id,
  name: venue.name,
  code: venue.code,
  controllerService: venue.service,
  controllerIp: venue.controller_ip,
  controllerUser: venue.controller_user,
  controllerPassword: venue.controller_password,
  termsAndConditionsLink: venue.terms_and_conditions_link,
  countryCode: venue.country_code,
  logoSubtitle: venue.logo_subtitle,
  politicsUrl: venue.politics_url,
  useApuestaTotal: Boolean(venue.use_apuesta_total),
  hasDiferentLogoLayout: Boolean(venue.has_diferent_logo_layout),
  hideFrame: Boolean(venue.hide_frame),
  hasPromotionVideo: Boolean(venue.has_promotion_video),
  promotionVideoUrl: venue.promotion_video,
  factorealContactListName: venue.factoreal_contact_list_name,
});
