import * as yup from 'yup';
import rgx from 'constants/regex';
import { imageValidation } from 'utils/yup';

export const CreateForm = yup.object({
  title: yup.string().required('El título del splash es requerido'),
  titleColor: yup
    .string()
    .required('El código de color del título es requerido')
    .matches(rgx.hexadecimalColorFormat, 'El formato del color debe ser hexadecimal. Ejemplo: #fff'),
  backgroundColor: yup.string()
    .when('backgroundMainImage', {
      is: (val) => val == null,
      then: () => yup.string().required('El código de color del fondo es requerido').matches(rgx.hexadecimalColorFormat, 'El formato del color debe ser hexadecimal. Ejemplo: #fff'),
      otherwise: () => yup.string(),
    }),  
  buttonColor: yup
    .string()
    .required('El código de color del botón es requerido')
    .matches(rgx.hexadecimalColorFormat, 'El formato del color debe ser hexadecimal. Ejemplo: #fff'),
  buttonTextColor: yup
    .string()
    .required('El código de color del botón es requerido')
    .matches(rgx.hexadecimalColorFormat, 'El formato del color debe ser hexadecimal. Ejemplo: #fff'),
  active: yup.boolean().required('El checkbox de activo es requerido'),
  conectionTime: yup
    .number()
    .required('El tiempo de conexión es requerido')
    .typeError('El tiempo de conexión debe ser un número entero'),
  urlToWeb: yup.string(),
  showEmail: yup.boolean().required('El checkbox de campo de email es requerido'),
  showIdentificationNumber: yup.boolean().required('El checkbox de campo de mostrar N° de identificación es requerido'),
  showPhone: yup.boolean().required('El checkbox de campo de email es requerido'),
  showAgeRange: yup.boolean().required('El checkbox de campo rango de edad es requerido'),
  showFullname: yup.boolean().required('El checkbox de campo de nombre completo es requerido'),
  venueId: yup.string().required('El venue es requerido'),
  promotionBannerImage: imageValidation('Imagen de banner', false),
  backgroundMainImage: imageValidation('Imagen de fondo', false),
  backgroundHeaderImage: imageValidation('Imagen de header', false),
  backgroundFooterImage: imageValidation('Imagen de footer', false),
});

export const AgeRangeConfigForm = yup.object({
  fromAge: yup.number().required('Es requerido').min(1),
  toAge: yup.number().required('Es requerido'),
});
