const AirplaneIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 48 48">
    <g fill="#fff">
      <path
        d="M10 16.5c.44 0 .87-.2 1.15-.54 1.02-1.22 4.35-5.41 4.35-7.96 0-3.03-2.47-5.5-5.5-5.5S4.5 4.97 4.5 8c0 2.55 3.33 6.74 4.35 7.96.28.34.71.54 1.15.54zm0-11A2.5 2.5 0 0 1 12.5 8c0 .78-1.15 2.74-2.5 4.57C8.65 10.74 7.5 8.78 7.5 8A2.5 2.5 0 0 1 10 5.5zm28 26a5.51 5.51 0 0 0-5.5 5.5c0 2.55 3.33 6.74 4.35 7.96.29.34.71.54 1.15.54s.87-.2 1.15-.54c1.02-1.22 4.35-5.41 4.35-7.96 0-3.03-2.47-5.5-5.5-5.5zm0 10.07c-1.35-1.83-2.5-3.79-2.5-4.57a2.5 2.5 0 0 1 5 0c0 .78-1.15 2.74-2.5 4.57z"
        data-original="#000000"
      />
      <path
        d="M32 42.5H12c-2.48 0-4.5-2.02-4.5-4.5s2.02-4.5 4.5-4.5h12c4.14 0 7.5-3.36 7.5-7.5s-3.36-7.5-7.5-7.5H13c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h11c2.48 0 4.5 2.02 4.5 4.5s-2.02 4.5-4.5 4.5H12c-4.14 0-7.5 3.36-7.5 7.5s3.36 7.5 7.5 7.5h20c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"
        data-original="#000000"
      />
    </g>
  </svg>
);

const VehicleIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 468 468">
    <path
      fill="#fff"
      d="m450.1 187.5-10.2-10.8-35.8-96.5C398 63.9 382.4 53.1 365 53H109.3c-17.4 0-33 10.9-39.1 27.2l-35.3 95.3-14.7 13.6C7.4 200 0 216 0 232.8v140.6c.1 23 18.8 41.6 41.8 41.6h26.4c23 0 41.7-18.6 41.8-41.6V353h248v20.4c.1 23 18.8 41.6 41.8 41.6h26.4c23 0 41.7-18.6 41.8-41.6V229.1c-.1-15.7-6.5-30.7-17.9-41.6zM89 87c3.2-8.5 11.3-14 20.3-14h255.8c9 0 17.1 5.6 20.3 14l31.2 84h-23.3c-5.5-37.5-40.4-63.4-77.9-57.8-29.9 4.4-53.4 27.9-57.8 57.8H57.8L89 87zm283.9 84h-95.1c5.5-26.3 31.3-43.1 57.6-37.6 18.8 4 33.5 18.7 37.5 37.6zM90 373.4c-.1 12-9.8 21.6-21.8 21.6H41.8c-12 0-21.7-9.6-21.8-21.6v-26.2c6 3.9 13.9 5.8 21.8 5.8H90v20.4zm358 0c-.1 12-9.8 21.6-21.8 21.6h-26.4c-12 0-21.7-9.7-21.8-21.6V353h48.2c7.9 0 15.8-1.9 21.8-5.8v26.2zM426.2 333H41.8c-11.9.1-21.7-9.4-21.8-21.4v-78.8c0-11 4.9-21.5 13.3-28.6l.4-.4L47.3 191H426l9.6 10.3c.1.2.4.3.5.5 7.5 7.1 11.8 17 11.8 27.3v82.5h.1c-.1 11.9-9.9 21.5-21.8 21.4z"
      data-original="#000000"
    />
    <path
      fill="#fff"
      d="M132 231H57c-5.5 0-10 4.5-10 10v52c0 5.5 4.5 10 10 10h75c5.5 0 10-4.5 10-10v-52c0-5.5-4.5-10-10-10zm-10 52H67v-32h55v32zm289-52h-75c-5.5 0-10 4.5-10 10v52c0 5.5 4.5 10 10 10h75c5.5 0 10-4.5 10-10v-52c0-5.5-4.5-10-10-10zm-10 52h-55v-32h55v32zm-118.7-10h-96.6c-5.5 0-10 4.5-10 10s4.5 10 10 10h96.6c5.5 0 10-4.5 10-10s-4.5-10-10-10zm0-31h-96.6c-5.5 0-10 4.5-10 10s4.5 10 10 10h96.6c5.5 0 10-4.5 10-10s-4.5-10-10-10z"
      data-original="#000000"
    />
  </svg>
);

const UsersIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 24 24">
    <path
      fill="#fff"
      d="M12 16a4 4 0 1 1 4-4 4 4 0 0 1-4 4Zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2Zm6 13a6 6 0 0 0-12 0 1 1 0 0 0 2 0 4 4 0 0 1 8 0 1 1 0 0 0 2 0Zm0-15a4 4 0 1 1 4-4 4 4 0 0 1-4 4Zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2Zm6 13a6.006 6.006 0 0 0-6-6 1 1 0 0 0 0 2 4 4 0 0 1 4 4 1 1 0 0 0 2 0ZM6 8a4 4 0 1 1 4-4 4 4 0 0 1-4 4Zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2ZM2 15a4 4 0 0 1 4-4 1 1 0 0 0 0-2 6.006 6.006 0 0 0-6 6 1 1 0 0 0 2 0Z"
    />
  </svg>
);

const DocsIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 24 24">
    <path
      fill="#fff"
      d="M17 14a1 1 0 0 1-1 1H8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-4 3H8a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2zm9-6.515V19a5.006 5.006 0 0 1-5 5H7a5.006 5.006 0 0 1-5-5V5a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486A6.951 6.951 0 0 1 22 10.485zm-6.949-7.021A5.01 5.01 0 0 0 14 2.684V7a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0-.781-1.05zM20 10.485c0-.165-.032-.323-.047-.485H15a3 3 0 0 1-3-3V2.047c-.162-.015-.321-.047-.485-.047H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3z"
    />
  </svg>
);

const DocsType = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 24 24">
    <path
      fill="#fff"
      d="M16 15a1 1 0 0 1-1 1H9a1 1 0 1 1 0-2h6a1 1 0 0 1 1 1Zm8-7v10c0 2.757-2.243 5-5 5H5c-2.757 0-5-2.243-5-5V6c0-2.757 2.243-5 5-5h2.528c.463 0 .927.109 1.341.316l3.156 1.578c.138.069.292.105.446.105h6.528c2.757 0 5 2.243 5 5ZM2 6v1h19.828A3.004 3.004 0 0 0 19 5h-6.528c-.463 0-.927-.109-1.341-.316L7.975 3.106a1.001 1.001 0 0 0-.446-.105H5.001c-1.654 0-3 1.346-3 3Zm20 12V9H2v9c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3Z"
    />
  </svg>
);

const StatusInstructionsIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 24 24">
    <g fill="#fff" fillRule="evenodd" clipRule="evenodd">
      <path
        d="M3.25 3c0-.967.783-1.75 1.75-1.75h14c.967 0 1.75.784 1.75 1.75v5.199a.75.75 0 0 1-1.5 0V3a.25.25 0 0 0-.25-.25H5a.25.25 0 0 0-.25.25v18c0 .138.112.25.25.25h5a.75.75 0 0 1 0 1.5H5A1.75 1.75 0 0 1 3.25 21z"
        data-original="#000000"
      />
      <path
        d="M7.25 7A.75.75 0 0 1 8 6.25h6.5a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 7zm0 3.5A.75.75 0 0 1 8 9.75h2.5a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75zm14 6.5a4.25 4.25 0 1 0-8.5 0 4.25 4.25 0 0 0 8.5 0zM17 11.25a5.75 5.75 0 1 1 0 11.5 5.75 5.75 0 0 1 0-11.5z"
        data-original="#000000"
      />
      <path d="M17 17.25a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0V18a.75.75 0 0 1 .75-.75z" data-original="#000000" />
    </g>
    <path fill="#fff" d="M17 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" data-original="#000000" />
  </svg>
);

const NotificationIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 24 24">
    <path
      fill="#fff"
      d="M10 8a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm14 2a14.27 14.27 0 0 1-11.484 13.952 2.95 2.95 0 0 1-2.431-.646A3.013 3.013 0 0 1 9 20.99v-1.041a10 10 0 1 1 9.382-15.394A1 1 0 1 1 16.7 5.646a8 8 0 1 0 0 8.708 1 1 0 1 1 1.677 1.091A9.959 9.959 0 0 1 11 19.937v1.053a1.016 1.016 0 0 0 .367.781.948.948 0 0 0 .791.213 12.192 12.192 0 0 0 9.47-9.053A2.955 2.955 0 0 1 21 13a3 3 0 1 1 3-3zm-2 0a1 1 0 1 0-1 1 1 1 0 0 0 1-1z"
    />
  </svg>
);

const TransitionIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 68 68">
    <path
      fill="#fff"
      d="M27 0h14a6 6 0 0 1 6 6v4a6 6 0 0 1-6 6H27a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6zm0 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 48h14a6 6 0 0 1 6 6v4a6 6 0 0 1-6 6H27a6 6 0 0 1-6-6v-4a6 6 0 0 1 6-6zm0 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2zm0-30h14a6 6 0 0 1 6 6v4a6 6 0 0 1-6 6H27a6 6 0 0 1-6-6v-4a6 6 0 0 1 6-6zm0 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2zM10.98 61.455C4.61 59.69 0 53.85 0 47c0-8.284 6.716-15 15-15h1a2 2 0 1 1 0 4h-1C8.925 36 4 40.925 4 47c0 5.098 3.486 9.436 8.262 10.657l-.977-1.628a2 2 0 0 1 3.43-2.058l3 5a2 2 0 0 1-.466 2.59l-5 4a2 2 0 0 1-2.498-3.123zm46.04-26 1.23.983a2 2 0 0 1-2.5 3.124l-5-4a2 2 0 0 1-.465-2.591l3-5a2 2 0 0 1 3.43 2.058l-.977 1.628A11.008 11.008 0 0 0 64 21c0-6.075-4.925-11-11-11h-1a2 2 0 1 1 0-4h1c8.284 0 15 6.716 15 15 0 6.847-4.609 12.69-10.98 14.455z"
      data-original="#000000"
    />
  </svg>
);

const PermissionIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 24 24" fill="#fff">
    <path d="M17 8H7V7a5 5 0 0 1 9.375-2.422 1 1 0 0 0 1.749-.971A7 7 0 0 0 5 7v1.424A5 5 0 0 0 2 13v6a5.006 5.006 0 0 0 5 5h10a5.006 5.006 0 0 0 5-5v-6a5.006 5.006 0 0 0-5-5Zm3 11a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-6a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3Z" />
    <path d="M12 14a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1Z" />
  </svg>
);

export {
  AirplaneIcon,
  DocsIcon,
  DocsType,
  NotificationIcon,
  PermissionIcon,
  StatusInstructionsIcon,
  UsersIcon,
  VehicleIcon,
  TransitionIcon,
};
