import React from 'react';
import SplashForm from 'forms/SplashForm';
import AppContext from 'contexts/AppContext';

export default (): JSX.Element => {
  const { setIsLoading } = React.useContext(AppContext);

  const load = React.useCallback(async () => {
    setIsLoading(true);
    //Necesario si se quiere traer más data
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    load();
  }, []);

  return (
    <SplashForm />
  );
};
