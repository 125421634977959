import React from 'react';
import { FormControl, IconButton, MenuItem, Select, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AppContext from 'contexts/AppContext';
import DropDown from 'components/DropDown';
import SearchBar from 'components/SearchBar';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { ArrowUpward, Delete, Edit, FilterList } from '@material-ui/icons';
import { destroyUser, getPaginatedUserEditors } from 'services/userService';
import type PaginationData from 'types/models/PaginationData';
import type User from 'types/models/User';
import type { UserFilterParams } from 'types/models/filters';
//import 'styles/pages/ListUsersPage.scss';
import 'styles/components/DataTable.scss';
import { parseDateTimeLumen } from 'utils/datetime';
import ProfileTypes from 'types/enums/ProfileTypes';
import EditUserContext from 'contexts/EditUserContext';
import ConfirmDialog from 'components/ConfirmDialog';

const userMenuItems = [
  { label: 'Nombre completo', value: 'fullname' },
  { label: 'Email', value: 'email' },
  { label: 'N° de identificación', value: 'identificationNumber' },
  { label: 'Rango de edad', value: 'ageRange' },
  { label: 'Código de venue', value: 'venueCode' },
];

export default (): JSX.Element => {
  const history = useHistory();
  const [showFilterDropDown, setShowFilterDropDown] = React.useState(false);
  const { isLoading, setIsLoading, authData } = React.useContext(AppContext);
  const { setSelectedUser, selectedUser } = React.useContext(EditUserContext);
  const [users, setUsers] = React.useState<PaginationData<User> | null>(null);
  const [userFilterParams, setUserFilterParams] = React.useState<UserFilterParams | null>(null);
  const [sortedField, setSortedField] = React.useState<{ field: string; sort: 'asc' | 'desc' }>({
    field: '',
    sort: 'asc',
  });
  const [openDialog, setOpenDialog] = React.useState(false);

  const loadData = React.useCallback(async (page?: number, userFilterParams?: UserFilterParams | null) => {
    setIsLoading(true);
    const users = await getPaginatedUserEditors(page, userFilterParams);

    if (users) setUsers(users);

    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    loadData();
  }, []);

  const handleTableCellClick = async (field: string) => {
    const sort = sortedField.sort === 'asc' ? 'desc' : 'asc';
    setSortedField({ field, sort });
    const filters: UserFilterParams = { ...userFilterParams, sortBy: field, sortDir: sort };
    setUserFilterParams(filters);
    loadData(1, filters);
  };

  const renderEditButton = (user: User | null): JSX.Element => {
    return (
      <IconButton
        color="inherit"
        onClick={() => {
          if (isLoading) return;
          if (!user) return;
          setSelectedUser(user);
          history.push(`/private/user-editors/${user.id}/edit`);
        }}
      >
        <Edit />
      </IconButton>
    );
  };

  const renderDeleteButton = (data: any): JSX.Element => (
    <IconButton
      color="inherit"
      onClick={() => {
        if (isLoading) return;
        if (!data) return;
        setSelectedUser(data);
        setOpenDialog(true);
      }}
    >
      <Delete />
    </IconButton>
  );

  const onCancel = (): void => {
    setOpenDialog(false);
  };

  const onConfirm = async (): Promise<void> => {
    await destroyUser(selectedUser?.id ?? '');
    loadData(1, userFilterParams);
  };

  const renderContent = (paginationData: User[] = []): JSX.Element => {
    return (
      <>
        {paginationData.map((user: User) => (
          <TableRow key={user.id}>
            <TableCell component="th" scope="row">
              {user.fullname ?? '-'}
            </TableCell>
            <TableCell component="th" scope="row">
              {user.email ?? '-'}
            </TableCell>
            <TableCell>{user.venue?.code ?? '-'}</TableCell>
            <TableCell>
              <div className="Action--buttons">
                {renderEditButton(user)}
                {renderDeleteButton(user)}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <ConfirmDialog open={openDialog} setOpen={setOpenDialog} onConfirm={onConfirm} onCancel={onCancel} />
      <section className="Table--header">
        <h3>Todos los editores</h3>
      </section>
      <div className="Table--wrapper">
        <div className="Filter--wrapper">
          <Button
            size="small"
            variant="contained"
            color="primary"
            className="Filter--button"
            onClick={() => setShowFilterDropDown(!showFilterDropDown)}
          >
            <span className="noabbr">
              <FilterList />
              <p>Filtrar</p>
            </span>
            <span className="abbr">
              <FilterList />
            </span>
          </Button>
          <DropDown
            show={showFilterDropDown}
            onPress={() => {
              const filters: UserFilterParams = { ...userFilterParams, sortBy: '', sortDir: undefined };
              loadData(1, filters);
              setShowFilterDropDown(false);
            }}
          >
            <>
              <section className="Filter--section">
                <p>Última conexión</p>
                <TextField
                  margin="dense"
                  fullWidth
                  name="pickupDate"
                  label={'Desde'}
                  type="datetime-local"
                  onChange={(event: any) => {
                    setUserFilterParams({ ...userFilterParams, fromDate: parseDateTimeLumen(event.target.value) });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  margin="dense"
                  fullWidth
                  name="dropoffDate"
                  label={'Hasta'}
                  type="datetime-local"
                  onChange={(event: any) => {
                    setUserFilterParams({ ...userFilterParams, toDate: parseDateTimeLumen(event.target.value) });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </section>
              <section className="Search--section">
                <p>Categorías</p>
                <FormControl fullWidth>
                  <Select
                    className="Select--filter"
                    variant="outlined"
                    onChange={(event: any) => {
                      setUserFilterParams({ ...userFilterParams, searchBy: event.target.value });
                    }}
                    defaultValue={0}
                  >
                    <MenuItem value={0}>Todas las categorías</MenuItem>
                    {userMenuItems.map(({ value, label }, index) => (
                      <MenuItem key={index} value={`${value}`}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </section>
            </>
          </DropDown>
          <SearchBar
            menuItems={userMenuItems}
            handleSelectChange={(event) => {
              setUserFilterParams({ ...userFilterParams, searchBy: event.target.value });
            }}
            handleInputChange={(event) => {
              setUserFilterParams({ ...userFilterParams, search: event.target.value.toLocaleLowerCase() });
            }}
            onPress={() => loadData(1, userFilterParams)}
            disabled={isLoading}
          />
          {authData?.user.profileTypeId === ProfileTypes.Admin && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              className="Filter--button"
              onClick={() => {
                history.push('user-editors/create');
              }}
            >
              <p>Nuevo editor</p>
            </Button>
          )}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table className="Mui--table">
          <TableHead>
            <TableRow>
              <TableCell className="Mui--tablecell" onClick={() => handleTableCellClick('fullname')}>
                <div className="Table--cell">
                  <p>Nombre</p>
                  <ArrowUpward
                    className={`Arrow--icon ${sortedField.field === 'fullname' ? `sortedBy ${sortedField.sort}` : ''}`}
                  />
                </div>
              </TableCell>
              <TableCell className="Mui--tablecell">
                <div className="Table--cell">
                  <p>Email</p>
                </div>
              </TableCell>
              <TableCell className="Mui--tablecell">
                <div className="Table--cell">
                  <p>Código de venue</p>
                </div>
              </TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.data ? (
              renderContent(users?.data)
            ) : (
              <TableRow>
                <TableCell colSpan={7} component="th" scope="row">
                  No hay viajes
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {users && (
        <TablePagination
          component="div"
          count={users.total}
          page={users.currentPage - 1}
          onPageChange={(_, page: number) => {
            loadData(page + 1, userFilterParams);
          }}
          rowsPerPage={users.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from} - ${to} de ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
