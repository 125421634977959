/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance from './axios';
import { joinUrlParams } from 'utils/joinUrlParams';
import type User from 'types/models/User';
import type PaginationData from 'types/models/PaginationData';
import type { UserFormRequest } from 'types/request/UserFormRequest';
import type { UserFilterParams } from 'types/models/filters';
import { ChangePasswordRequest } from 'types/request/ChangePasswordRequest';
import { parseVenue } from './venueServices';

export const getPaginatedUsers = async (
  page = 1,
  userFilterParams?: UserFilterParams | null,
): Promise<PaginationData<User> | null> => {
  let url = `users?page=${page}`;

  if (userFilterParams) {
    url = joinUrlParams(userFilterParams, url);
  }

  try {
    const { data: paginationData } = await axiosInstance({
      url,
      method: 'get',
    });
    return {
      data: parseUsers(paginationData.data),
      currentPage: paginationData.current_page,
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateUser = async (id: string, user: UserFormRequest): Promise<User | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `user-editors/${id}`,
      data: user,
    });

    return parseUser(data);
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const exportUsers = async (
  userFilterParams?: UserFilterParams | null,
  withFilter = false,
): Promise<string | null> => {
  const fromDate = userFilterParams?.fromDate === '' ? undefined : userFilterParams?.fromDate;
  const toDate = userFilterParams?.toDate === '' ? undefined : userFilterParams?.toDate;

  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'users/export',
      data: {
        ...userFilterParams,
        fromDate,
        toDate,
        withFilter: String(withFilter),
      },
    });

    return data.url;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const changePassword = async (id: string, body: ChangePasswordRequest): Promise<User | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `users/${id}/change-password`,
      data: body,
    });

    return parseUser(data);
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const storeUser = async (user: UserFormRequest): Promise<User | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `user-editors`,
      data: user,
    });

    return parseUser(data);
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};

export const destroyUser = async (id: string): Promise<User | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `user-editors/${id}`,
    });
    return parseUser(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCouriers = async (): Promise<User[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'couriers/by-client',
    });
    return parseUsers(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getUser = async (userId: string): Promise<User | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `users/${userId}`,
    });

    return parseUser(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};


export const getUsers = async (): Promise<User[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: 'users/index',
    });
    return parseUsers(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPaginatedUserEditors = async (
  page = 1,
  userFilterParams?: UserFilterParams | null,
): Promise<PaginationData<User> | null> => {
  let url = `user-editors?page=${page}`;

  if (userFilterParams) {
    url = joinUrlParams(userFilterParams, url);
  }

  try {
    const { data: paginationData } = await axiosInstance({
      url,
      method: 'get',
    });
    return {
      data: parseUsers(paginationData.data),
      currentPage: paginationData.current_page,
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};


const parseUsers = (data: any): User[] => {
  return data.map((item) => parseUser(item));
};

export const parseUser = (data: any): User => ({
  id: data.id,
  fullname: data.fullname,
  email: data.email,
  identificationNumber: data.identification_number,
  ueIp: data.ue_ip,
  ueMac: data.ue_mac,
  ageRange: data.age_range,
  phone: data.phone,
  venue: data.venue ? parseVenue(data.venue) : undefined,
  profileTypeId: data.profile_type_id,
  showUserSection: Boolean(data.show_user_section),
  showSplashSection: Boolean(data.show_splash_section),
  showUserSplashSection: Boolean(data.show_user_splash_section)
});

export const parseProfileHasPermissions = (data: any[]): string[] => {
  return data.map((item) => {
    if (item.active) return item.permission.slug;
  });
};
