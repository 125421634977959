import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import EditUserContext from 'contexts/EditUserContext';
import type User from 'types/models/User';
import EditUserPage from 'pages/private/common/EditUserPage';
import CreateUserPage from 'pages/private/common/CreateUserPage';
import ListUserEditorPage from 'pages/private/common/ListUserEditorPage';

export default ({ match }: RouteComponentProps): JSX.Element => {
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

  return (
    <EditUserContext.Provider value={{ selectedUser, setSelectedUser }}>
      <Switch>
        <Route exact={true} path={`${match.url}`} component={ListUserEditorPage} />
        <Route exact={true} path={`${match.url}/:userId/edit`} component={EditUserPage} />
        <Route exact={true} path={`${match.url}/create`} component={CreateUserPage} />
        <Redirect to="/private/welcome" />
      </Switch>
    </EditUserContext.Provider>
  );
};
