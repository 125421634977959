import React from 'react';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import AppContext from 'contexts/AppContext';
import AppHeader from 'components/AppHeader';
import { refreshAuth } from 'services/authServices';
import type AuthData from 'types/models/AuthData';
import type User from 'types/models/User';

const NotFoundDataError = function (this: any, message = 'Response returnes nullish') {
  this.name = 'NotFoundDataError';
  this.message = message;
};

const NotFoundAuthError = function (this: any, message = 'Logged user was found') {
  this.name = 'NotFoundAuthError';
  this.message = message;
};

export default (): JSX.Element => {
  const [authData, setAuthData] = React.useState<AuthData | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const history = useHistory();

  if (!authData) {
    const storageAuthData = localStorage.getItem('auth-data');
    if (storageAuthData) {
      setAuthData(JSON.parse(storageAuthData));
    }
  }

  const initializeUser = async () => {
    try {
      const storagedUser = localStorage.getItem('auth-data');

      if (!storagedUser) throw new NotFoundAuthError();

      const authData: AuthData = JSON.parse(storagedUser);
      const token: string = authData.token;
      const user: User = authData.user;

      const updatedUser = await refreshAuth(user.id);

      if (!updatedUser) throw new NotFoundDataError();

      const auth = { token, user: updatedUser };
      localStorage['auth-data'] = JSON.stringify(auth);
      setAuthData(auth);
    } catch (e) {
      if (e instanceof NotFoundDataError) {
        setAuthData(null);
        console.warn('No user found, returning to login');
        if (history) {
          history.push('/public/login');
        }
      } else if (e instanceof NotFoundAuthError) {
        return;
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      await initializeUser();
    })();
  }, []);

  return (
    <BrowserRouter>
      <LoadingSpinner isLoading={isLoading} />
      <AppContext.Provider value={{ authData, setAuthData, isLoading, setIsLoading }}>
        <AppHeader />
        <div className="app-container">
          <Switch>
            {/* Private Routes */}
            <Route path="/private" component={PrivateRoutes} />
            {/* Public Routes */}
            <Route path="/public" component={PublicRoutes} />
            <Redirect to={`${authData ? '/private/welcome' : '/public/login'}`} />
          </Switch>
        </div>
      </AppContext.Provider>
    </BrowserRouter>
  );
};
