import React from 'react';
import { useHistory } from 'react-router';
import AppContext from 'contexts/AppContext';
import UserForm from 'forms/UserForm';
import { getVenues } from 'services/venueServices';
import Venue from 'types/models/Venue';

export default (): JSX.Element => {
  const history = useHistory();
  const [venues, setVenues] = React.useState<Venue[]>([]);
  const { setIsLoading } = React.useContext(AppContext);

  const load = React.useCallback(async () => {
    setIsLoading(true);
    const venues = await getVenues();
    setVenues(venues)

    if (!venues) {
      setIsLoading(false);
      history.push('/private/common/users');
      return;
    }
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    load();
  }, []);

  return <UserForm venues={venues} />;
};
