import React from 'react';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import AppContext from 'contexts/AppContext';
import DropDown from 'components/DropDown';
import SearchBar from 'components/SearchBar';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { ArrowUpward, FilterList, GetApp } from '@material-ui/icons';
import { getPaginatedUsers, exportUsers } from 'services/userService';
import type PaginationData from 'types/models/PaginationData';
import type User from 'types/models/User';
import type { UserFilterParams } from 'types/models/filters';
import 'styles/components/DataTable.scss';
import { parseDateTimeLumen } from 'utils/datetime';

const userMenuItems = [
  { label: 'Nombre completo', value: 'fullname' },
  { label: 'Email', value: 'email' },
  { label: 'N° de identificación', value: 'identificationNumber' },
  { label: 'Rango de edad', value: 'ageRange' },
  { label: 'Código de venue', value: 'venueCode' },
];

export default (): JSX.Element => {
  const [showFilterDropDown, setShowFilterDropDown] = React.useState(false);
  const { isLoading, setIsLoading } = React.useContext(AppContext);
  const [users, setUsers] = React.useState<PaginationData<User> | null>(null);
  const [userFilterParams, setUserFilterParams] = React.useState<UserFilterParams | null>(null);
  const [sortedField, setSortedField] = React.useState<{ field: string; sort: 'asc' | 'desc' }>({
    field: '',
    sort: 'asc',
  });
  const [withFilter, setWithFilter] = React.useState<boolean>(false);

  const loadData = React.useCallback(
    async (page?: number, userFilterParams?: UserFilterParams | null) => {
      setIsLoading(true);
      const users = await getPaginatedUsers(page, userFilterParams);

      if (users) setUsers(users);

      setIsLoading(false);
    },
    [],
  );

  React.useEffect(() => {
    loadData();
  }, []);

  const getXlsxUsers = async () => {
    const data = await exportUsers(userFilterParams, withFilter);
    const a = document.createElement('a');

    if (data) {
      a.href = data;
      a.target = '_blank';
      a.click();
    }
  };

  const handleTableCellClick = async (field: string) => {
    const sort = sortedField.sort === 'asc' ? 'desc' : 'asc';
    setSortedField({ field, sort });
    const filters: UserFilterParams = { ...userFilterParams, sortBy: field, sortDir: sort };
    setUserFilterParams(filters);
    setWithFilter(true);
    loadData(1, filters);
  };

  const renderContent = (paginationData: User[] = []): JSX.Element => {
    return (
      <>
        {paginationData.map((user: User) => (
          <TableRow key={user.id}>
            <TableCell component="th" scope="row">
              {user.fullname ?? '-'}
            </TableCell>
            <TableCell component="th" scope="row">
              {user.email ?? '-'}
            </TableCell>
            <TableCell component="th" scope="row">
              {user.identificationNumber ?? '-'}
            </TableCell>
            <TableCell>{user.ageRange ?? '-'}</TableCell>
            <TableCell>{user.phone ?? '-'}</TableCell>
            <TableCell>{user.venue?.code ?? '-'}</TableCell>
            <TableCell>{user.ueMac ?? '-'}</TableCell>
            <TableCell>{user.ueIp ?? '-'}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <section className="Table--header">
        <h3>Todos los usuarios</h3>
      </section>
      <div className="Table--wrapper">
        <div className="Filter--wrapper">
          <Button
            size="small"
            variant="contained"
            color="primary"
            className="Filter--button"
            onClick={() => setShowFilterDropDown(!showFilterDropDown)}
          >
            <span className="noabbr">
              <FilterList />
              <p>Filtrar</p>
            </span>
            <span className="abbr">
              <FilterList />
            </span>
          </Button>
          <DropDown
            show={showFilterDropDown}
            onPress={() => {
              const filters: UserFilterParams = { ...userFilterParams, sortBy: '', sortDir: undefined };
              setWithFilter(true);
              loadData(1, filters);
              setShowFilterDropDown(false);
            }}
          >
            <>
              <section className="Filter--section">
                <p>Última conexión</p>
                <TextField
                  margin="dense"
                  fullWidth
                  name="pickupDate"
                  label={'Desde'}
                  type="datetime-local"
                  onChange={(event: any) => {
                    setUserFilterParams({ ...userFilterParams, fromDate: parseDateTimeLumen(event.target.value) });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  margin="dense"
                  fullWidth
                  name="dropoffDate"
                  label={'Hasta'}
                  type="datetime-local"
                  onChange={(event: any) => {
                    setUserFilterParams({ ...userFilterParams, toDate: parseDateTimeLumen(event.target.value) });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </section>
              <section className="Search--section">
                <p>Categorías</p>
                <FormControl fullWidth>
                  <Select
                    className="Select--filter"
                    variant="outlined"
                    onChange={(event: any) => {
                      setUserFilterParams({ ...userFilterParams, searchBy: event.target.value });
                    }}
                    defaultValue={0}
                  >
                    <MenuItem value={0}>Todas las categorías</MenuItem>
                    {userMenuItems.map(({ value, label }, index) => (
                      <MenuItem key={index} value={`${value}`}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </section>
            </>
          </DropDown>
          <SearchBar
            menuItems={userMenuItems}
            handleSelectChange={(event) => {
              setUserFilterParams({ ...userFilterParams, searchBy: event.target.value });
            }}
            handleInputChange={(event) => {
              setUserFilterParams({ ...userFilterParams, search: event.target.value.toLocaleLowerCase() });
            }}
            onPress={() => loadData(1, userFilterParams)}
            disabled={isLoading}
          />
          <Button size="small" variant="contained" color="primary" className="Filter--button" onClick={getXlsxUsers}>
            <GetApp />
            <p>Exportar</p>
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table className="Mui--table">
          <TableHead>
            <TableRow>
              <TableCell className="Mui--tablecell" onClick={() => handleTableCellClick('fullname')}>
                <div className="Table--cell">
                  <p>Nombre</p>
                  <ArrowUpward
                    className={`Arrow--icon ${sortedField.field === 'fullname' ? `sortedBy ${sortedField.sort}` : ''}`}
                  />
                </div>
              </TableCell>
              <TableCell className="Mui--tablecell">
                <div className="Table--cell">
                  <p>Email</p>
                </div>
              </TableCell>
              <TableCell className="Mui--tablecell" onClick={() => handleTableCellClick('origin_address')}>
                <div className="Table--cell">
                  <p>DNI</p>
                  <ArrowUpward
                    className={`Arrow--icon ${sortedField.field === 'origin_address' ? `sortedBy ${sortedField.sort}` : ''
                      }`}
                  />
                </div>
              </TableCell>
              <TableCell className="Mui--tablecell">
                <div className="Table--cell">
                  <p>Rango de edad</p>
                </div>
              </TableCell>
              <TableCell className="Mui--tablecell">
                <div className="Table--cell">
                  <p>Teléfono</p>
                </div>
              </TableCell>
              <TableCell className="Mui--tablecell">
                <div className="Table--cell">
                  <p>Venue Code</p>
                </div>
              </TableCell>
              <TableCell className="Mui--tablecell">
                <div className="Table--cell">
                  <p>UE MAC</p>
                </div>
              </TableCell>
              <TableCell className="Mui--tablecell">
                <div className="Table--cell">
                  <p>UE IP</p>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.data ? (
              renderContent(users?.data)
            ) : (
              <TableRow>
                <TableCell colSpan={7} component="th" scope="row">
                  No hay viajes
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {users && (
        <TablePagination
          component="div"
          count={users.total}
          page={users.currentPage - 1}
          onPageChange={(_, page: number) => {
            loadData(page + 1, userFilterParams);
          }}
          rowsPerPage={users.perPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from} - ${to} de ${count !== -1 ? count : '0'}`;
          }}
        />
      )}
    </>
  );
};
