enum SplashImageType {
  Logo = 1,
  promotionImage,
  PromotionBannerImage,
  BackgroundHeader,
  BackgroundMain,
  BackgroundFooter,
}

export default SplashImageType;
