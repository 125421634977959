/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SplashImageType from 'types/enums/SplashImageType';
import axiosInstance from './axios';
import PaginationData from 'types/models/PaginationData';
import Splash, { AgeRangeConfig } from 'types/models/Splash';
import { SplashFilterParams } from 'types/models/filters';
import { joinUrlParams } from 'utils/joinUrlParams';
import { parseVenue } from './venueServices';

export const getSplashesWithPagination = async (
  page = 1,
  splashFilterParams?: SplashFilterParams | null,
  withFilter = false,
): Promise<PaginationData<Splash> | null> => {
  let url = `splashes?page=${page ?? 1}&withFilter=${withFilter.toString()}`;

  if (splashFilterParams) {
    url = joinUrlParams(splashFilterParams, url);
  }

  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseSplashes(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateSplash = async (splashId: string, splash: FormData): Promise<Splash | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `splashes/${splashId}?_method=put`,
      data: splash,
    });
    return parseSplash(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeSplash = async (splash: FormData): Promise<Splash | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `splashes`,
      data: splash,
    });
    return parseSplash(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeAgeRangeConfig = async (ageRangeConfig: any): Promise<AgeRangeConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `splashes/age-range-configs`,
      data: ageRangeConfig,
    });
    return parseAgeRangeConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const destroyAgeRangeConfig = async (ageRangeConfigId: number): Promise<AgeRangeConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `splashes/age-range-configs/${ageRangeConfigId}`,
    });
    return parseAgeRangeConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateAgeRangeConfig = async (ageConfig: AgeRangeConfig): Promise<AgeRangeConfig | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `splashes/age-range-configs/${ageConfig.id}`,
      data: ageConfig,
    });
    return parseAgeRangeConfig(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseAgeRangeConfig = (data: any): AgeRangeConfig => ({
  id: data.id,
  fromAge: data.from_age,
  toAge: data.to_age,
});

export const destroySplash = async (id: string): Promise<Splash | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `splashes/${id}`,
    });
    return parseSplash(data);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const approveSplash = async (id: string): Promise<Splash | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `splashes/${id}/approve`,
    });
    return parseSplash(data);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const declineSplash = async (id: string): Promise<Splash | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'put',
      url: `splashes/${id}/decline`,
    });
    return parseSplash(data);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getSplash = async (splashId: string): Promise<Splash | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `splashes/${splashId}`,
    });
    return parseSplash(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const exportSplashes = async (
  splashFilterParams?: SplashFilterParams | null,
  withFilter = false,
): Promise<Blob | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'splashes/export',
      responseType: 'blob',
      data: {
        ...splashFilterParams,
        withFilter: String(withFilter),
      },
    });

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const parseSplashes = (data: any): Splash[] => {
  return data.map((item: any) => parseSplash(item));
};

const parseSplash = (splash: any): Splash => ({
  id: splash.id,
  headerColor: splash.header_color,
  backgroundColor: splash.background_color,
  footerColor: splash.footer_color,
  buttonColor: splash.button_color,
  buttonTextColor: splash.button_text_color,
  venueId: splash.venue_id,
  active: Boolean(splash.active),
  title: splash.title,
  titleColor: splash.title_color,
  urlToLogin: splash.url_to_login,
  urlToWeb: splash.url_to_web,
  conectionTime: splash.conection_time,
  logo: {
    type: SplashImageType.Logo,
    path: splash.logo_path,
  },
  promotionImage: {
    type: SplashImageType.promotionImage,
    path: splash.promotion_image_path,
  },
  promotionBannerImage: {
    type: SplashImageType.promotionImage,
    path: splash.promotion_banner_image_path,
  },
  showEmail: Boolean(splash.show_email),
  emailIsRequired: Boolean(splash.email_is_required),
  showPhone: Boolean(splash.show_phone),
  phoneIsRequired: Boolean(splash.phone_is_required),
  showFullname: Boolean(splash.show_fullname),
  fullnameIsRequired: Boolean(splash.fullname_is_required),
  showIdentificationNumber: Boolean(splash.show_nacionality),
  identificationNumberIsRequired: Boolean(splash.identification_number_is_required),
  showAgeRange: Boolean(splash.show_age_range),
  ageRangeIsRequired: Boolean(splash.age_range_is_required),
  betWidgetUrl: splash.bet_widget_url,
  ageRangeConfigs: splash.age_range_configs ? parseAgeRangeConfigs(splash.age_range_configs) : [],
  venue: splash.venue ? parseVenue(splash.venue) : undefined,
  identificationNumberLabel: splash.identification_number_label,
  apuestaTotalClientUrl: splash.apuesta_total_client_url,
  apuestaTotalNoClientUrl: splash.apuesta_total_no_client_url,
  backgroundHeaderImage: splash.background_header_image_path ? {
    type: SplashImageType.BackgroundHeader,
    path: splash.background_header_image_path,
  } : undefined,
  backgroundMainImage: splash.background_main_image_path ? {
    type: SplashImageType.BackgroundMain,
    path: splash.background_main_image_path,
  } : undefined,
  backgroundFooterImage: splash.background_footer_image_path ? {
    type: SplashImageType.BackgroundFooter,
    path: splash.background_footer_image_path,
  } : undefined,
});

const parseAgeRangeConfigs = (configs: any): AgeRangeConfig[] => {
  return configs.map((config) => ({
    id: config.id,
    fromAge: config.from_age,
    toAge: config.to_age,
  }));
};
