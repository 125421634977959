import axiosInstance from './axios';
import { parseUser } from './userService';
import type AuthData from 'types/models/AuthData';
import type User from 'types/models/User';

export const loginEmail = async (formData: FormData): Promise<AuthData | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'login/moderator',
      data: formData,
    });
    return parseAuthData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const refreshAuth = async (userId: string): Promise<User | null> => {
  try {
    const { data } = await axiosInstance({ method: 'get', url: `login/${userId}` });
    return parseUser(data);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const parseAuthData = (data: any): AuthData => {
  return {
    token: data.token,
    user: parseUser(data.user),
  };
};
