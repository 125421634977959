import React from 'react';
import { useHistory } from 'react-router';
import AppContext from 'contexts/AppContext';
import EditUserContext from 'contexts/EditUserContext';
import Venue from 'types/models/Venue';
import { getVenues } from 'services/venueServices';
import UserForm from 'forms/UserForm';

export default (): JSX.Element => {
  const history = useHistory();
  const [venues, setVenues] = React.useState<Venue[]>([]);
  const { selectedUser } = React.useContext(EditUserContext);
  const { setIsLoading } = React.useContext(AppContext);

  const load = React.useCallback(async () => {
    setIsLoading(true);
    const venues = await getVenues();
    setVenues(venues);

    if (!venues) {
      setIsLoading(false);
      history.push('/private/common/user-editors');
      return;
    }
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    if (!selectedUser) {
      history.push('/private/common/user-editors');
    } else {
      console.log('user', selectedUser);
      load();
    }
  }, []);

  if (!selectedUser) {
    return <></>;
  }
  return <UserForm venues={venues} user={selectedUser} />;
};
